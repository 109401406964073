import {Dispatch} from "redux";

import {isEmpty} from "@pg-mono/nodash";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {Country} from "../../region/types/Country";
import {RegionType} from "../../region/types/RegionType";
import {fetchOfferList, IOfferQueryResponse, resetOfferList, stopFetchOfferList} from "./fetch_search_offers_action";
import {AutocompletePrediction, optimizedFetchPlaceList, resetPlaceList, stopFetchPlaceList} from "./fetch_search_places_action";
import {fetchRegionList, IRegionListResponse, resetRegionList, stopFetchRegionList} from "./fetch_search_regions_action";
import {fetchVendorList, IVendorListResponse, resetVendorList, stopFetchVendorList} from "./fetch_search_vendors_action";

export enum SearchTab {
    Regions,
    Places,
    Offers,
    Vendors,
    Holiday
}

const FETCH_ALL_LISTS = "multi-query/FETCH_ALL_LISTS";
export const fetchAllListsType = createRequestActionTypes(FETCH_ALL_LISTS);

export interface IFetchAllSearchListsParams {
    searchContent: {regions: boolean; vendors: boolean; offers: boolean; googlePOIs: boolean};
    regionType?: RegionType;
    country?: Country;
}

// We need to be able to switch tabs always from region down to the places.
// We want to prevent situation in which offers were fetched faster than regions so instead of rendering regions we do render offers.
export const fetchAllSearchLists =
    (searchInput: string, params: IFetchAllSearchListsParams) =>
    (dispatch: Dispatch): Promise<SearchTab> => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const enablePOIFetch = params.searchContent.googlePOIs && params.country !== Country.SPAIN;
            dispatch({type: fetchAllListsType.start});

            const regionsPromise =
                params.searchContent.regions &&
                dispatch(
                    fetchRegionList(searchInput, {
                        ...(params.regionType ? {type: params.regionType} : {}),
                        ...(params.country ? {country: params.country} : {})
                    })
                ).then((res: IRegionListResponse) => {
                    if (res && res.results && !isEmpty(res.results)) {
                        resolve(SearchTab.Regions); // SWITCH to regions
                        return SearchTab.Regions; // regions found
                    }
                });

            const offersPromise =
                params.searchContent.offers &&
                dispatch(fetchOfferList(searchInput, params.country)).then(async (res: IOfferQueryResponse) => {
                    if (res && res.results && !isEmpty(res.results)) {
                        const regionTab = await regionsPromise;
                        if (regionTab != null) {
                            // regions found so do not resolve main promise
                            return true; // offers found
                        }

                        // resolve main promise
                        resolve(SearchTab.Offers); // SWITCH to offers
                        return SearchTab.Offers; // offers found
                    }
                });

            const vendorsPromise =
                params.searchContent.vendors &&
                dispatch(fetchVendorList(searchInput, params.country)).then(async (res: IVendorListResponse) => {
                    if (res && res.results && !isEmpty(res.results)) {
                        const regionTab = await regionsPromise;
                        if (regionTab != null) {
                            // regions found so do not resolve main promise
                            return true; // vendors found
                        }
                        const offerTab = await offersPromise;
                        if (offerTab != null) {
                            // offers found so do not resolve main promise
                            return true;
                        }
                        resolve(SearchTab.Vendors);
                        return SearchTab.Vendors;
                    }
                });

            if ((await regionsPromise) != null && params.searchContent.regions) {
                dispatch({type: fetchAllListsType.success});
                // regions found so do not resolve main promise
                return;
            }
            if ((await offersPromise) != null && params.searchContent.offers) {
                dispatch({type: fetchAllListsType.success});
                // offers found so do not resolve main promise
                return;
            }
            if ((await vendorsPromise) != null && params.searchContent.vendors) {
                dispatch({type: fetchAllListsType.success});
                // vendors found so do not resolve main promise
                return;
            }
            // hit places only when all previous lists are empty
            if (enablePOIFetch) {
                dispatch(optimizedFetchPlaceList(searchInput)).then(async (_predictions: AutocompletePrediction[]) => {
                    // do not check if `predictions` is empty because even so we should land on `PlacesTab`
                });
                dispatch({type: fetchAllListsType.success});

                resolve(SearchTab.Places);
                return;
            }
            // resolve main promise
            resolve(SearchTab.Places); // SWITCH to places
            dispatch({type: fetchAllListsType.success});
            // TODO: timeout to reject
        });
    };

export const stopFetchAllSearchLists = () => (dispatch: Dispatch) => {
    dispatch({type: fetchAllListsType.error});
    dispatch(stopFetchRegionList());
    dispatch(stopFetchPlaceList());
    dispatch(stopFetchOfferList());
    dispatch(stopFetchVendorList());
};

export const resetFetchAllSearchLists = () => (dispatch: Dispatch) => {
    dispatch({type: fetchAllListsType.reset});
    dispatch(resetRegionList());
    dispatch(resetPlaceList());
    dispatch(resetOfferList());
    dispatch(resetVendorList());
};
import {Dispatch} from "redux";

import {isEmpty} from "@pg-mono/nodash";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {Country} from "../../region/types/Country";
import {RegionType} from "../../region/types/RegionType";
import {fetchOfferList, IOfferQueryResponse, resetOfferList, stopFetchOfferList} from "./fetch_search_offers_action";
import {AutocompletePrediction, optimizedFetchPlaceList, resetPlaceList, stopFetchPlaceList} from "./fetch_search_places_action";
import {fetchRegionList, IRegionListResponse, resetRegionList, stopFetchRegionList} from "./fetch_search_regions_action";
import {fetchVendorList, IVendorListResponse, resetVendorList, stopFetchVendorList} from "./fetch_search_vendors_action";

export enum SearchTab {
    Regions,
    Places,
    Offers,
    Vendors,
    Holiday
}

const FETCH_ALL_LISTS = "multi-query/FETCH_ALL_LISTS";
export const fetchAllListsType = createRequestActionTypes(FETCH_ALL_LISTS);

export interface IFetchAllSearchListsParams {
    searchContent: {regions: boolean; vendors: boolean; offers: boolean; googlePOIs: boolean};
    regionType?: RegionType;
    country?: Country;
}

// We need to be able to switch tabs always from region down to the places.
// We want to prevent situation in which offers were fetched faster than regions so instead of rendering regions we do render offers.
export const fetchAllSearchLists =
    (searchInput: string, params: IFetchAllSearchListsParams) =>
    (dispatch: Dispatch): Promise<SearchTab> => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const enablePOIFetch = params.searchContent.googlePOIs && params.country !== Country.SPAIN;
            dispatch({type: fetchAllListsType.start});

            const regionsPromise =
                params.searchContent.regions &&
                dispatch(
                    fetchRegionList(searchInput, {
                        ...(params.regionType ? {type: params.regionType} : {}),
                        ...(params.country ? {country: params.country} : {})
                    })
                ).then((res: IRegionListResponse) => {
                    if (res && res.results && !isEmpty(res.results)) {
                        resolve(SearchTab.Regions); // SWITCH to regions
                        return SearchTab.Regions; // regions found
                    }
                });

            const offersPromise =
                params.searchContent.offers &&
                dispatch(fetchOfferList(searchInput, params.country)).then(async (res: IOfferQueryResponse) => {
                    if (res && res.results && !isEmpty(res.results)) {
                        const regionTab = await regionsPromise;
                        if (regionTab != null) {
                            // regions found so do not resolve main promise
                            return true; // offers found
                        }

                        // resolve main promise
                        resolve(SearchTab.Offers); // SWITCH to offers
                        return SearchTab.Offers; // offers found
                    }
                });

            const vendorsPromise =
                params.searchContent.vendors &&
                dispatch(fetchVendorList(searchInput, params.country)).then(async (res: IVendorListResponse) => {
                    if (res && res.results && !isEmpty(res.results)) {
                        const regionTab = await regionsPromise;
                        if (regionTab != null) {
                            // regions found so do not resolve main promise
                            return true; // vendors found
                        }
                        const offerTab = await offersPromise;
                        if (offerTab != null) {
                            // offers found so do not resolve main promise
                            return true;
                        }
                        resolve(SearchTab.Vendors);
                        return SearchTab.Vendors;
                    }
                });

            if ((await regionsPromise) != null && params.searchContent.regions) {
                dispatch({type: fetchAllListsType.success});
                // regions found so do not resolve main promise
                return;
            }
            if ((await offersPromise) != null && params.searchContent.offers) {
                dispatch({type: fetchAllListsType.success});
                // offers found so do not resolve main promise
                return;
            }
            if ((await vendorsPromise) != null && params.searchContent.vendors) {
                dispatch({type: fetchAllListsType.success});
                // vendors found so do not resolve main promise
                return;
            }
            // hit places only when all previous lists are empty
            if (enablePOIFetch) {
                dispatch(optimizedFetchPlaceList(searchInput)).then(async (_predictions: AutocompletePrediction[]) => {
                    // do not check if `predictions` is empty because even so we should land on `PlacesTab`
                });
                dispatch({type: fetchAllListsType.success});

                resolve(SearchTab.Places);
                return;
            }
            // resolve main promise
            resolve(SearchTab.Places); // SWITCH to places
            dispatch({type: fetchAllListsType.success});
            // TODO: timeout to reject
        });
    };

export const stopFetchAllSearchLists = () => (dispatch: Dispatch) => {
    dispatch({type: fetchAllListsType.error});
    dispatch(stopFetchRegionList());
    dispatch(stopFetchPlaceList());
    dispatch(stopFetchOfferList());
    dispatch(stopFetchVendorList());
};

export const resetFetchAllSearchLists = () => (dispatch: Dispatch) => {
    dispatch({type: fetchAllListsType.reset});
    dispatch(resetRegionList());
    dispatch(resetPlaceList());
    dispatch(resetOfferList());
    dispatch(resetVendorList());
};
